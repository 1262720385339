

//import logo from './logo.svg';
//import './App.css';
  
//function App() {
 // return (
   // <div className="App">
      //   <header className="App-header">
       // <img src={logo} className="App-logo" alt="logo" />
      //  <p>
      //    Edit <code>src/App.js</code> and save to reload.
     //   </p>
     //   <a
      //    className="App-link"
       //   href="https://rcd my-appcd my-appeactjs.org"
       //   target="_blank"
       //   rel="noopener noreferrer"
      //  >
      //    I love React!
      //  </a>
     // </header>
   // </div>
 // );
//}
  
//export default App;

import "./App.css";
import Khalti from "./components/Khalti/Khalti";


function App(){
  return (
    <div className="App">
      <h2>Khalti Integration in React.js Project</h2>
      <Khalti/>
    </div>
  )
}

export default App;
